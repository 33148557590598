import React, { useState, useEffect } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifSelect,
  MotifOption,
  MotifMessage,
  MotifErrorMessage,
} from "@ey-xd/motif-react";
import Slider from "@mui/material/Slider";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import "./DmaModal.scss";

const StakeHolder = ({
  onClose,
  setName,
  handleDoneClick,
  modalMode,
  NameLabel,
  TypeLabel,
  IELabel,
  InfluenceLabel,
  PowerLabel,
  DescriptionLabel,
  cancelButtonLabel,
  saveButtonLabel,
  updateButtonLabel,
  selectedRow,
  typeOptions,
  ieOptions,
}) => {
  const isEditMode = modalMode === "edit";

  const [formData, setFormData] = useState({
    name: "",
    type: 0,
    ie: 0,
    influence: 0,
    power: 0,
    description: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedRow && isEditMode) {
      setFormData({
        name: selectedRow.name || "",
        type: selectedRow.stakeHolderType.id || 0,
        ie: selectedRow.stakeHolderIntExtType.id || 0,
        influence: parseFloat(selectedRow.influence) || 0,
        power: parseFloat(selectedRow.power) || 0,
        description: selectedRow.description || "",
      });
    }
  }, [selectedRow, isEditMode]);

  const validateForm = () => {
    const newErrors = {};
    const { name, type, ie, influence, power } = formData;

    if (!name.trim()) newErrors.name = "Name is required";
    if (!type) newErrors.type = "Type is required";
    if (!ie) newErrors.ie = "I/E is required";
    if (influence < 0 || influence > 10)
      newErrors.influence = "Influence must be between 0 and 10";
    if (power < 0 || power > 10)
      newErrors.power = "Power must be between 0 and 10";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleSave = () => {
    if (validateForm()) {
      handleDoneClick({
        name: formData.name,
        typeId: formData.type,
        intExtTypeId: formData.ie,
        influence: formData.influence,
        power: formData.power,
        description: formData.description,
      });
    }
  };

  const renderError = (error) =>
    error && <MotifErrorMessage className="error-text">{error}</MotifErrorMessage>;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>{setName}</h2>
        </div>
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group full-width">
              <MotifLabel>{NameLabel}</MotifLabel>
              <MotifFormField>
                <MotifInput
                  className="session-modal-input session-name-input"
                  aria-describedby="session-name"
                  hideClearButton={true}
                  value={formData.name}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                  error={!!errors.name}
                />
                {renderError(errors.name)}
              </MotifFormField>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <MotifMessage id="session-framework">{TypeLabel}</MotifMessage>
              <MotifFormField>
                <MotifLabel id="select-type-label">Select Type</MotifLabel>
                <MotifSelect
                  onChange={(e) => handleInputChange("type", e)}
                  value={formData.type}
                  ariaLabelledBy="select-type-label"
                  error={!!errors.type}
                >
                  {typeOptions.map(({ value, label }) => (
                    <MotifOption key={value} value={value}>
                      {label}
                    </MotifOption>
                  ))}
                </MotifSelect>
                {renderError(errors.type)}
              </MotifFormField>
            </div>
            <div className="form-group">
              <MotifMessage id="session-framework">{IELabel}</MotifMessage>
              <MotifFormField>
                <MotifLabel id="select-ie-label">Select I/E</MotifLabel>
                <MotifSelect
                  onChange={(e) => handleInputChange("ie", e)}
                  value={formData.ie}
                  ariaLabelledBy="select-ie-label"
                  error={!!errors.ie}
                >
                  {ieOptions.map(({ value, label }) => (
                    <MotifOption key={value} value={value}>
                      {label}
                    </MotifOption>
                  ))}
                </MotifSelect>
                {renderError(errors.ie)}
              </MotifFormField>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <MotifLabel>{InfluenceLabel}</MotifLabel>
              <MotifFormField>
                <Slider
                  value={formData.influence}
                  color="#E6E6E9"
                  min={0}
                  max={10}
                  onChange={(e) =>
                    handleInputChange("influence", parseFloat(e.target.value))
                  }
                  valueLabelDisplay="auto"
                  marks={[
                    { value: 0, label: "0" },
                    { value: 10, label: "10" },
                  ]}
                />
                {renderError(errors.influence)}
              </MotifFormField>
            </div>
            <div className="form-group">
              <MotifLabel>{PowerLabel}</MotifLabel>
              <MotifFormField>
                <Slider
                  value={formData.power}
                  color="#E6E6E9"
                  min={0}
                  max={10}
                  onChange={(e) =>
                    handleInputChange("power", parseFloat(e.target.value))
                  }
                  valueLabelDisplay="auto"
                  marks={[
                    { value: 0, label: "0" },
                    { value: 10, label: "10" },
                  ]}
                />
                {renderError(errors.power)}
              </MotifFormField>
            </div>
          </div>
          <MotifLabel>{DescriptionLabel}</MotifLabel>
          <TextField
            multiline
            fullWidth
            rows={4}
            value={formData.description}
            onChange={(e) => handleInputChange("description", e.target.value)}
            error={!!errors.description}
            helperText={errors.description}
          />
        </div>
        <div className="modal-footer">
          <MotifButton onClick={onClose} className="button cancel-button">
            {cancelButtonLabel}
          </MotifButton>
          <MotifButton
            onClick={handleSave}
            className="button done-button"
          >
            {isEditMode ? updateButtonLabel : saveButtonLabel}
          </MotifButton>
        </div>
      </div>
    </div>
  );
};

StakeHolder.propTypes = {
  onClose: PropTypes.func.isRequired,
  setName: PropTypes.string.isRequired,
  handleDoneClick: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  NameLabel: PropTypes.string.isRequired,
  TypeLabel: PropTypes.string.isRequired,
  IELabel: PropTypes.string.isRequired,
  InfluenceLabel: PropTypes.string.isRequired,
  PowerLabel: PropTypes.string.isRequired,
  DescriptionLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  selectedRow: PropTypes.object,
  typeOptions: PropTypes.array.isRequired,
  ieOptions: PropTypes.array.isRequired,
};

export default StakeHolder;
