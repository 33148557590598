import { configureStore } from "@reduxjs/toolkit";
import projectDetailsReducer from "./slices/ProjectScreens";
import fetchAllProjectsReducer from "./slices/InstanceHome";
import fetchAllUsersReducer from "./slices/UsersData";
import getAllStatusSliceReducer from "./slices/InstanceMaintenance";
import fetchAllGlSliceReducer from "./slices/DMAGeographicalLocation";
import dmaOverviewReducer from "./slices/DMAOverview";
import dmaProcessSpecificationReducer from "./slices/DMAProcessSpecificationThreshold";
import dmaValueChainReducer from "./slices/DMAValueChain";
import dmaApsSliceReducer from "./slices/DMAAps";
import dmaSourcesSliceReducer from "./slices/DMASources";
import dmaStakeHolderReducer from "./slices/DMAStakeHolder";
import dmaSustainabilityMattersReducer from "./slices/SustainabilityMatters";
import homeSliceReducer from "./slices/HomeSlice";
import iroSliceReducer from "./slices/Iro";
import fileManagerReducer from "./slices/FileManager";
import likelihoodSliceReducer from "./slices/Likelihood";
import scoresSliceReducer from "./slices/Scores";
import DMAIroAssessmentReducer from "./slices/DMAIroAssessment";
import fetchExcelDataReducer from "./slices/LongListData";
import dgaSustainabilityMattersReducer from "./slices/DGAContext";

export const store = configureStore({
  reducer: {
    user: homeSliceReducer,
    projectDetails: projectDetailsReducer,
    allProjects: fetchAllProjectsReducer,
    allUsers: fetchAllUsersReducer,
    allStatus: getAllStatusSliceReducer,
    allGldata: fetchAllGlSliceReducer,
    dmaProcessSpecification: dmaProcessSpecificationReducer,
    dmaOverview: dmaOverviewReducer,
    dmaValueChain: dmaValueChainReducer,
    dmaAps: dmaApsSliceReducer,
    dmaStakeHolder: dmaStakeHolderReducer,
    dmaSources: dmaSourcesSliceReducer,
    dmaSustainabilityMatters: dmaSustainabilityMattersReducer,
    iro: iroSliceReducer,
    fileManager: fileManagerReducer,
    likelihood: likelihoodSliceReducer,
    scores: scoresSliceReducer,
    dmaIroAssessment: DMAIroAssessmentReducer,
    fetchExcelData: fetchExcelDataReducer,
    dgaSustainabilityMatters: dgaSustainabilityMattersReducer,
  },
});
