import React, { useState } from "react";
import { TextField, Typography, Box } from "@mui/material";
import {
  MotifButton,
  MotifFormField,
  MotifToggleSwitch,
  MotifMessage,
} from "@ey-xd/motif-react";
import "./CommentBox.scss";

const CommentBox = ({
  label = "Climate change",
  onSave,
  onCancel,
  cancelButtonLabel,
  saveButtonLabel,
  isMaterial,
}) => {
  const [isToggled, setIsToggled] = useState(false);
  const [comment, setComment] = useState("");

  const handleToggle = () => setIsToggled(!isToggled);
  const handleSave = () => onSave(comment, isToggled);
  const handleCancel = () => {
    setComment("");
    onCancel && onCancel();
  };

  return (
    <Box className="comment-toggle-container">
      <Box className="comment-toggle-header">
        <Typography variant="body1">{label}</Typography>
        <Box className="comment-toggle-right">
          <MotifFormField className="human-rights-toggle">
            <MotifToggleSwitch
              onChange={handleToggle}
              id="exampleToggle"
              hideLabels={true}
              checked={isToggled}
            />
          </MotifFormField>
          <MotifMessage data-testid="human-rights">{isMaterial}</MotifMessage>
        </Box>
      </Box>
      <TextField
        label="Comment"
        multiline
        rows={25}
        variant="outlined"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        fullWidth
      />
      <Box className="comment-toggle-actions">
        <MotifButton onClick={handleCancel} className="button cancel-button">
          {cancelButtonLabel}
        </MotifButton>
        <MotifButton onClick={handleSave} className="button done-button">
          {saveButtonLabel}
        </MotifButton>
      </Box>
    </Box>
  );
};

export default CommentBox;
