import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { getDGASustainabilityMatters } from "../../../features/slices/DGAContext";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import TreeView from "../../../components/treeView/TreeView";
import ContextSelection from "../../../components/contextSelection/ContextSelection";
import { useTranslation } from "react-i18next";
import CommentBox from "../../../components/commentBox/CommentBox";
import "./AssessmentDga.scss";

const AssessmentDga = () => {
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // Local isLoading state
  const { moduleId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = location.state?.token;
  const { t } = useTranslation();
  const [smData, setSmData] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading to true before fetching data
      try {
        const [smResponse] =
          await Promise.all([
            dispatch(getDGASustainabilityMatters({ moduleId, token })),
          ]);

        setSmData(smResponse.payload.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Set loading to false after fetching data
      }
    };
    fetchData();
  }, [dispatch, moduleId, updateTrigger, token]);

  const findItemById = (data, id) => {
    for (let item of data) {
      if (item.id === id) return item;
      if (item.children) {
        const result = findItemById(item.children, id);
        if (result) return result;
      }
    }
    return null;
  };

  const transformedItems =
    smData.map((item) => ({
      id: item.id,
      itemId: item.id,
      label: item.label || item.name,
      children: item.children|| [],
    })) || [];
  const handleSave = (comment, isToggled) => {
    console.log("Saved comment:", comment);
    console.log("Is material toggled:", isToggled);
  };
  const handleSearchInputChange = (event) => {
    setSearchInput(event);
  };
  const handleCancel = () => {
    console.log("Cancelled");
  };

  return (
    <>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      {!isLoading && (
        <div style={{ padding: "2% 9% 1% 6% " }}>
          <ContextSelection
            pageName="DgaContext"
            downloadButtonText="Download longlist"
            onChangeSearchInput={handleSearchInputChange}
          />
        </div>
      )}
      <div className="motif-row dga-assessment">
        <div className="motif-col-1 motif-col-lg-8">
          <div className="sm-file-explorer">
            <TreeView
              items={transformedItems}
              pageName="DgaContext"
              defaultExpandedItems={[]}
              defaultSelectedItems="1"
            />
          </div>
        </div>
        <div className="motif-col-1 motif-col-lg-4">
          <div style={{ width: "100%", height: "80vh" }}>
            <CommentBox
              onSave={handleSave}
              onCancel={handleCancel}
              cancelButtonLabel={t("Cancel")}
              saveButtonLabel={t("DoneButton")}
              isMaterial={t("isMaterial")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentDga;
