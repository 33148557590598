import React from "react";
import {
  MotifCard,
  MotifCardHeader,
  MotifCardBody,
  MotifCardFooter,
} from "@ey-xd/motif-react";
import { IconButton } from "@mui/material";
import { Edit, Delete } from "../../constants/icons/Icons";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./CardDMS.scss";

const CardDMS = ({
  header,
  descriptionHeaderText,
  descriptionBodyText,
  pageName,
  influence,
  power,
  stakeHolderType,
  handleEdit,
  handleDelete,
}) => {
  const { t } = useTranslation();
  return (
    <MotifCard className="dms-card">
      <MotifCardHeader variant="alt">
        <div className="header">{header}</div>
      </MotifCardHeader>
      <MotifCardBody>
        {pageName === "StakeHolder" && (
          <div className="metrics">
            <div className="metric">
              <div className="metric-value">{influence}</div>
              <div className="metric-label">{t("Influence")}</div>
            </div>
            <div className="metric">
              <div className="metric-value">{power}</div>
              <div className="metric-label">{t("Power")}</div>
            </div>
          </div>
        )}
        <p className="body1">{descriptionHeaderText}</p>
        <div className="body-container">
          {pageName === "StakeHolder" && (
            <p className="body2">{descriptionBodyText}</p>
          )}
          {pageName === "StakeHolder" && (
            <p className="stake-holder-type">{stakeHolderType}</p>
          )}
          {pageName === "ValueChain" && (
            <p className="holder-type">{stakeHolderType}</p>
          )}
          {pageName === "APS" && (
            <p className="holder-type">{stakeHolderType}</p>
          )}
          {pageName === "Sources" && (
            <p className="holder-type">{stakeHolderType}</p>
          )}
        </div>
      </MotifCardBody>
      <MotifCardFooter className="footer">
        <IconButton onClick={handleEdit}>
          <Edit />
        </IconButton>
        <IconButton onClick={handleDelete}>
          <Delete />
        </IconButton>
      </MotifCardFooter>
    </MotifCard>
  );
};

CardDMS.propTypes = {
  header: PropTypes.string.isRequired,
  descriptionHeaderText: PropTypes.string.isRequired,
  descriptionBodyText: PropTypes.string,
  pageName: PropTypes.string.isRequired,
  influence: PropTypes.number,
  power: PropTypes.number,
  stakeHolderType: PropTypes.string,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default CardDMS;
