import React from "react";
import WaterfallChart from "../../../components/charts/WaterfallChart";

const DMAReporting = () => {
  const data = [
    { label: "Climate change", value: 7, cumulative: 7, type: "Environmental" },
    { label: "Pollution", value: 8, cumulative: 15, type: "Environmental" },
    { label: "Water", value: 1, cumulative: 16, type: "Environmental" },
    { label: "Biodiversity", value: 4, cumulative: 20, type: "Environmental" },
    { label: "Circular economy", value: 5, cumulative: 25, type: "Social" },
    { label: "Own workforce", value: 15, cumulative: 40, type: "Social" },
    { label: "Workers ", value: 7, cumulative: 47, type: "Social" },
    { label: "Affected Communities", value: 2, cumulative: 49, type: "Social" },
    { label: "Consumers", value: 0, cumulative: 49, type: "Social" },
    { label: "Cyber Security", value: 3, cumulative: 52, type: "Governance" },
    { label: "Business conduct", value: 4, cumulative: 56, type: "Governance" },
  ];
  const data1 = [
    { label: "Climate change", value: 1, cumulative: 1, type: "Environmental" },
    { label: "Pollution", value: 0, cumulative: 0, type: "Environmental" },
    { label: "Water", value: 0, cumulative: 0, type: "Environmental" },
    { label: "Biodiversity", value: 4, cumulative: 4, type: "Environmental" },
    { label: "Circular economy", value: 3, cumulative: 3, type: "Social" },
    { label: "Own workforce", value: 1.5, cumulative: 1.5, type: "Social" },
    { label: "Workers ", value: 1.2, cumulative: 1.2, type: "Social" },
    {
      label: "Affected Communities",
      value: 1.1,
      cumulative: 1.2,
      type: "Social",
    },
    { label: "Consumers", value: 1.6, cumulative: 1.6, type: "Social" },
    { label: "Cyber Security", value: 0, cumulative: 0, type: "Governance" },
    { label: "Business conduct", value: 4, cumulative: 4, type: "Governance" },
  ];

  return (
    <div>
      <div className="motif-row" style={{ background: "#FFFFFF" }}>
        <div className="motif-col-6 motif-col-xl-6">
          <WaterfallChart data={data} />
        </div>
        <div className="motif-col-6 motif-col-xl-6">
          <WaterfallChart data={data1} />
        </div>
      </div>
      <div className="motif-row" style={{ background: "#FFFFFF" }}>
        <div className="motif-col-6 motif-col-xl-6">
          <WaterfallChart data={data1} />
        </div>
        <div className="motif-col-6 motif-col-xl-6">
          <WaterfallChart data={data} />
        </div>
      </div>
    </div>
  );
};

export default DMAReporting;
