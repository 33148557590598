import React, { useState, useEffect, useCallback } from "react";
import "./NewProject.scss";
import {
  MotifCard,
  MotifCardBody,
  MotifCardFooter,
  MotifCardHeader,
  MotifButton,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import Header from "../../components/headers/Header";
import NewProjectForm from "../../components/forms/NewProjectForm";
import { newProjectFormFields } from "./NewProjectData";
import { useDispatch, useSelector } from "react-redux";
import {
  createProject,
  fetchCountries,
  fetchSectors,
} from "../../features/slices/ProjectScreens";
import { Back } from "../../constants/icons/Icons";
import { useNavigate, useLocation } from "react-router-dom";
import ErrorModal from "../../components/modals/error/errorModal";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { setUser, getPhoto, setJwtToken } from "../../features/slices/HomeSlice";

const NewProject = (props) => {
  const [formState, setFormState] = useState({
    projectName: "",
    clientName: "",
    engagementCode: "",
    country: "",
    channel: "",
    projectSize: "",
    clientSector: "",
    projectAdmin: [],
  });
  const location = useLocation();
  const email = location.state?.email;
  const userId = location.state?.userId;
  const name = location.state?.name;
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [sectorList, setSectorList] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isError: isProjectCreateError, errorMessage } = useSelector(
    (state) => state.projectDetails
  );
  const [formFields, setFormFields] = useState(newProjectFormFields);
  const { instance, accounts, inProgress } = useMsal();
  const token = useSelector((state) => state.user.jwtToken);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const serializeAccount = useCallback((account) => {
    return {
      ...account,
      tenantProfiles: account.tenantProfiles
        ? Object.fromEntries(account.tenantProfiles)
        : {},
    };
  }, []);

  useEffect(() => {
    const initializeAuth = async () => {
      if (!instance) {
        console.error("MSAL instance is not initialized");
        return;
      }
      try {
        let token;
        if (accounts.length === 0 && inProgress === "none") {
          const loginResponse = await instance.loginRedirect({
            scopes: ["https://database.windows.net/user_impersonation"],
          });
          token = loginResponse.accessToken;
          const serializedAccount = serializeAccount(loginResponse.account);
          sessionStorage.setItem("JwtToKen", token);
          dispatch(setUser(serializedAccount));
          dispatch(setJwtToken(token));
          dispatch(getPhoto());
        } else if (accounts.length > 0) {
          const tokenResponse = await instance.acquireTokenSilent({
            scopes: ["https://database.windows.net/user_impersonation"],
            account: accounts[0],
          });
          token = tokenResponse.accessToken;
          const serializedAccount = serializeAccount(accounts[0]);
          sessionStorage.setItem("JwtToKen", token);
          dispatch(setUser(serializedAccount));
          dispatch(setJwtToken(token));
          dispatch(getPhoto());
        }

        if (token) {
          dispatch(fetchCountries({ token })).then((response) => {
            if (response.payload) {
              setCountryList(response.payload);
            }
          });
          dispatch(fetchSectors({ token })).then((response) => {
            if (response.payload) {
              setSectorList(response.payload);
            }
          });
        }
      } catch (error) {
        console.error("Authentication failed", error);
      }
    };

    if (instance) {
      initializeAuth();
    } else {
      console.error("MSAL instance not available");
    }
  }, [instance, accounts, inProgress, dispatch, serializeAccount]);

  useEffect(() => {
    if (countryList?.data) {
      setFormFields((prevFields) =>
        prevFields.map((field) => {
          if (field.id === "countryId") {
            return {
              ...field,
              options: countryList?.data.map((country) => ({
                value: country.id,
                label: country.name,
              })),
            };
          }
          return field;
        })
      );
    } else {
      console.log("Countries data is not available");
    }
    if (sectorList?.data) {
      setFormFields((prevFields) =>
        prevFields.map((field) => {
          if (field.id === "clientSector") {
            return {
              ...field,
              options: sectorList?.data.map((sector) => ({
                value: sector.id,
                label: `${sector.name} - ${sector.subName}`,
              })),
            };
          }
          return field;
        })
      );
    } else {
      console.log("Sectors data is not available");
    }
  }, [countryList, sectorList]);

  useEffect(() => {
    // Prepopulate the projectAdmin field with the user details from location.state
    if (name && email && userId) {
      setFormState((prevState) => ({
        ...prevState,
        projectAdmin: [
          {
            id: userId,
            displayName: name,
            emailId: email,
          },
        ],
      }));
    }
  }, [name, email, userId]);

  const handleBack = () => {
    navigate("/");
  };

  const isFormValid = () => {
    return (
      formState.projectName.trim() !== "" &&
      formState.clientName.trim() !== "" &&
      formState.countryId > 0 &&
      formState.channel.trim() !== "" &&
      formState.projectSize.trim() !== "" &&
      formState.clientSector > 0 &&
      formState.projectAdmin.length > 0
    );
  };

  const handleSubmit = () => {
    if (isFormValid()) {
      setIsSubmitting(true);
      const projectData = {
        name: formState.projectName,
        clientName: formState.clientName,
        engagementCode: formState.engagementCode,
        countryId: formState.countryId,
        isChannel1: formState.channel === "yes",
        size: formState.projectSize,
        adminUsers: formState.projectAdmin.map((admin) => ({
          aadId: admin.id,
          name: admin.displayName,
          email: admin.emailId,
          projectRoleId: 2,
        })),
        statusId: 1,
        sectorId: formState.clientSector,
      };
      dispatch(createProject({ projectData, token }))
        .unwrap()
        .then((response) => {
          if (response.success) {
            if (response.data) {
              navigate(`/project-home/${response.data}`, { state: { token } });
            } else {
              alert("Project created, but no data received to navigate.");
            }
          }
        })
        .catch((error) => {
          console.error("Creation failed:", error);
        })
        .finally(() => setIsSubmitting(false));
    } else {
      alert("Please fill in all required fields.");
    }
  };

  const handleError = () => {
    navigate("/");
  };

  if (isProjectCreateError) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessage}
          handleButtonClick={handleError}
          deleteButtonLabel={t("Reload")}
        />
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div className="Container">
        <div id="inline">
          <MotifButton onClick={handleBack} className="button done-button">
            {" "}
            <Back />
            &nbsp;
            {t("backButton")}
          </MotifButton>
        </div>

        <MotifCard
          orientation="horizontal"
          variant="card-border"
          className="new-project-card"
        >
          {isSubmitting && (
            <MotifProgressLoader className="loader" show variant="default" />
          )}
          <MotifCardHeader>
            <span className="motif-h6-default-regular">{t("newProject")}</span>
          </MotifCardHeader>
          <MotifCardBody>
            <NewProjectForm
              formFields={formFields}
              formState={formState}
              setFormState={setFormState}
              setSelectedUsers={setSelectedUsers}
            />
          </MotifCardBody>
          <MotifCardFooter className="new-project-footer">
            <MotifButton onClick={handleError} className="button cancel-button">
              {t("Cancel")}
            </MotifButton>
            <MotifButton
              className="button done-button"
              onClick={handleSubmit}
              disabled={!isFormValid() || isSubmitting}
            >
              {t("submitButton")}
            </MotifButton>
          </MotifCardFooter>
        </MotifCard>
      </div>
    </div>
  );
};

export default NewProject;