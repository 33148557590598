import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import DMACards from "./dmaCards/DMACards";
import { Stake, Iro, Reporting } from "../../../constants/icons/Icons";
import {
  fetchUnderstandingTheContextCount,
  fetchIROCount,
} from "../../../features/slices/DMAOverview";
import { fetchExcelData } from "../../../features/slices/LongListData";
import ErrorModal from "../../../components/modals/error/errorModal";
import { useTranslation } from "react-i18next";

export const DMAOverview = ({ onTabChange }) => {
  const dispatch = useDispatch();
  const { moduleId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const token = location.state?.token;
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("sources");

  const [excelData, setExcelData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Track loading state for both context and Excel data
  
  const { contextData, iroData, isError, errorMessage } = useSelector(
    (state) => state.dmaOverview
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await dispatch(fetchUnderstandingTheContextCount({ moduleId, token }));
        await dispatch(fetchIROCount({ moduleId, token }));
      } catch (error) {
        console.error("Error fetching context data:", error);
      }
      setIsLoading(false);
    };
    
    fetchData();
  }, [dispatch, moduleId, token]);

  useEffect(() => {
    const fetchExcel = async () => {
      setIsLoading(true);
      try {
        const response = await dispatch(fetchExcelData({ moduleId, token }));
        setExcelData(response.payload.data || []);
      } catch (error) {
        console.error("Error fetching Excel data:", error);
      }
      setIsLoading(false);
    };

    fetchExcel();
  }, [dispatch, moduleId, token]);

  const formatExcelData = (data) => {
    const workbook = XLSX.utils.book_new();

    const addSheet = (sheetName, data) => {
      // Gather unique names for each section
      const uniqueSourceTypes = Array.from(
        new Set(
          data.flatMap((item) =>
            item.sources.map((source) => source.sourceType)
          )
        )
      );
      const uniqueSourceNames = Array.from(
        new Set(
          data.flatMap((item) => item.sources.map((source) => source.name))
        )
      );
      const uniqueValueChainNames = Array.from(
        new Set(data.flatMap((item) => item.valueChain.map((vc) => vc.name)))
      );
      const uniqueStakeholderNames = Array.from(
        new Set(data.flatMap((item) => item.stakeholders.map((st) => st.name)))
      );
      const uniqueGeographyNames = Array.from(
        new Set(data.flatMap((item) => item.geographies.map((geo) => geo.name)))
      );
      const uniqueActProServiceNames = Array.from(
        new Set(
          data.flatMap((item) => item.actProServices.map((aps) => aps.name))
        )
      );

      // Top-level headers
      const headers = [
        "Section",
        "ESRS",
        "Topic",
        "SubTopic",
        "SubSubTopic",
        ...Array(uniqueSourceTypes.length * uniqueSourceNames.length).fill(
          "Sources"
        ),
        ...Array(uniqueValueChainNames.length).fill("Value Chain"),
        ...Array(uniqueStakeholderNames.length).fill("Stakeholders"),
        ...Array(uniqueGeographyNames.length).fill("Geographies"),
        ...Array(uniqueActProServiceNames.length).fill("ActProServices"),
      ];

      // Sub-headers with individual names
      const subHeaders = [
        "Section",
        "ESRS",
        "Topic",
        "SubTopic",
        "SubSubTopic",
        ...uniqueSourceTypes.flatMap((type) =>
          uniqueSourceNames.map((name) => `${type} - ${name}`)
        ),
        ...uniqueValueChainNames,
        ...uniqueStakeholderNames,
        ...uniqueGeographyNames,
        ...uniqueActProServiceNames,
      ];

      // Start worksheet data with headers and sub-headers
      const worksheetData = [headers, subHeaders];

      // Populate rows based on item data
      data.forEach((item) => {
        const row = [
          item.section,
          item.esrs,
          item.topic,
          item.subTopic,
          item.subSubTopic,
        ];

        // For each source, mark "X" if active
        uniqueSourceTypes.forEach((type) => {
          uniqueSourceNames.forEach((name) => {
            const isActive = item.sources.some(
              (src) =>
                src.sourceType === type && src.name === name && src.isActive
            );
            row.push(isActive ? "X" : "");
          });
        });

        // For each value chain, mark "X" if active
        uniqueValueChainNames.forEach((name) => {
          const isActive = item.valueChain.some(
            (vc) => vc.name === name && vc.isActive
          );
          row.push(isActive ? "X" : "");
        });

        // For each stakeholder, mark "X" if active
        uniqueStakeholderNames.forEach((name) => {
          const isActive = item.stakeholders.some(
            (st) => st.name === name && st.isActive
          );
          row.push(isActive ? "X" : "");
        });

        // For each geography, mark "X" if active
        uniqueGeographyNames.forEach((name) => {
          const isActive = item.geographies.some(
            (geo) => geo.name === name && geo.isActive
          );
          row.push(isActive ? "X" : "");
        });

        // For each ActProService, mark "X" if active
        uniqueActProServiceNames.forEach((name) => {
          const isActive = item.actProServices.some(
            (aps) => aps.name === name && aps.isActive
          );
          row.push(isActive ? "X" : "");
        });

        worksheetData.push(row);
      });

      // Create worksheet and add it to the workbook
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

      // Merge cells for top-level headers
      const sourcesStartCol = 5;
      const sourcesEndCol =
        sourcesStartCol +
        uniqueSourceTypes.length * uniqueSourceNames.length -
        1;
      const valueChainStartCol = sourcesEndCol + 1;
      const valueChainEndCol =
        valueChainStartCol + uniqueValueChainNames.length - 1;
      const stakeholdersStartCol = valueChainEndCol + 1;
      const stakeholdersEndCol =
        stakeholdersStartCol + uniqueStakeholderNames.length - 1;
      const geographiesStartCol = stakeholdersEndCol + 1;
      const geographiesEndCol =
        geographiesStartCol + uniqueGeographyNames.length - 1;
      const actProServicesStartCol = geographiesEndCol + 1;
      const actProServicesEndCol =
        actProServicesStartCol + uniqueActProServiceNames.length - 1;

      worksheet["!merges"] = [
        { s: { r: 0, c: sourcesStartCol }, e: { r: 0, c: sourcesEndCol } }, // Merge "Sources" header
        {
          s: { r: 0, c: valueChainStartCol },
          e: { r: 0, c: valueChainEndCol },
        }, // Merge "Value Chain" header
        {
          s: { r: 0, c: stakeholdersStartCol },
          e: { r: 0, c: stakeholdersEndCol },
        }, // Merge "Stakeholders" header
        {
          s: { r: 0, c: geographiesStartCol },
          e: { r: 0, c: geographiesEndCol },
        }, // Merge "Geographies" header
        {
          s: { r: 0, c: actProServicesStartCol },
          e: { r: 0, c: actProServicesEndCol },
        }, // Merge "ActProServices" header
      ];

      // Set column widths for better visibility
      worksheet["!cols"] = Array(headers.length).fill({ wpx: 100 });

      return worksheet;
    };

    // Format data and add sheet to workbook
    addSheet("Sustainability Matters", data);

    return workbook;
  };

  // Function to trigger Excel download
  const handleDownloadExcel = () => {
    const workbook = formatExcelData(excelData);
    XLSX.writeFile(workbook, "sustainability_matters.xlsx");
  };

  const handleError = () => {
    navigate("/");
  };

  if (isError) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessage}
          handleButtonClick={handleError}
          deleteButtonLabel={t("Reload")}
        />
      </div>
    );
  }

  return (
    <div className="dma-overview-container">
      {isLoading && (
        <MotifProgressLoader className="loader" show variant="default" />
      )}
      <DMACards
        headerIconLeft={<Stake />}
        lefttype={t("UTC")}
        UTC={t("UTC")}
        references={contextData?.data}
        headerIconRight={<Reporting />}
        Longlist={t("Longlist")}
        Shortlist={t("Shortlist")}
        Dashboard={t("Dashboard")}
        ReportingImplication={t("ReportingImplication")}
        rightHeaderType={t("ReportingImplication")}
        onTabChange={onTabChange}
        onLonglistClick={handleDownloadExcel} // Pass the download function here
      />
      <DMACards
        headerIconLeft={<Iro />}
        headerIconRight={<Stake />}
        references={iroData?.data}
        lefttype={t("IROIdentificationAndAssessment")}
        IROIdentificationAndAssessment={t("IROIdentificationAndAssessment")}
        rightHeaderType={t("StakeholderOutreach")}
        StakeholderOutreach={t("StakeholderOutreach")}
        onTabChange={setActiveTab}
      />
    </div>
  );
};

export default DMAOverview;