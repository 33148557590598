import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

const WaterfallChart = ({ data, width = 800, height = 400 }) => {
  const svgRef = useRef();

  useEffect(() => {
    // Clean up any previous chart before rendering
    d3.select(svgRef.current).selectAll("*").remove();

    // Setup margins and dimensions
    const margin = { top: 60, right: 20, bottom: 80, left: 50 };
    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    // Compute cumulative values for the waterfall effect
    let cumulative = 0;
    const waterfallData = data.map(d => {
      const newData = { ...d, start: cumulative };
      cumulative += d.value;
      newData.end = cumulative;
      return newData;
    });

    // Calculate total for the stacked bar at the end
    const totalEnvironmental = d3.sum(data, d => (d.type === "Environmental" ? d.value : 0));
    const totalSocial = d3.sum(data, d => (d.type === "Social" ? d.value : 0));
    const totalGovernance = d3.sum(data, d => (d.type === "Governance" ? d.value : 0));
    const total = totalEnvironmental + totalSocial + totalGovernance;

    // Add a "Total" entry for the stacked bar
    const stackedData = [
      { label: "Total", type: "Environmental", value: totalEnvironmental, start: 0, end: totalEnvironmental },
      { label: "Total", type: "Social", value: totalSocial, start: totalEnvironmental, end: totalEnvironmental + totalSocial },
      { label: "Total", type: "Governance", value: totalGovernance, start: totalEnvironmental + totalSocial, end: total },
    ];

    const extendedData = [...waterfallData, ...stackedData];

    // Append SVG to the DOM
    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Set up x and y scales
    const x = d3
      .scaleBand()
      .domain(extendedData.map((d) => d.label))
      .range([0, chartWidth])
      .padding(0.3);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(extendedData, (d) => d.end)])
      .nice()
      .range([chartHeight, 0]);

    // Create x-axis with multi-line wrapping for labels
    svg
      .append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll(".tick text")
      .call(wrapText, x.bandwidth());

    // Function to wrap text to multiple lines if needed
    function wrapText(text, width) {
      text.each(function () {
        const text = d3.select(this);
        const words = text.text().split(/\s+/).reverse();
        let word;
        let line = [];
        const lineHeight = 1.5; // ems
        const y = text.attr("y");
        const dy = parseFloat(text.attr("dy"));
        let tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");

        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", lineHeight + "em").text(word);
          }
        }
      });
    }

    // Add the bars for the waterfall chart
    svg
      .selectAll(".bar")
      .data(waterfallData)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => x(d.label))
      .attr("y", (d) => y(d.end))
      .attr("width", x.bandwidth())
      .attr("height", (d) => chartHeight - y(d.value))
      .attr("fill", (d) => {
        if (d.type === "Environmental") return "#1f77b4"; // Blue
        if (d.type === "Social") return "#17becf"; // Cyan
        if (d.type === "Governance") return "#2ca02c"; // Green
        return "#888"; // Default grey
      });

    // Add connecting lines between bars
    svg
      .selectAll(".connector")
      .data(waterfallData.slice(0, -1)) // Exclude the last bar
      .enter()
      .append("line")
      .attr("class", "connector")
      .attr("x1", (d) => x(d.label) + x.bandwidth() / 2)
      .attr("x2", (d, i) => x(waterfallData[i + 1].label) + x.bandwidth() / 2)
      .attr("y1", (d) => y(d.end))
      .attr("y2", (d, i) => y(waterfallData[i + 1].start))
      .attr("stroke", "#999")
      .attr("stroke-width", 1); // Solid lines

    // Add the stacked bar for the total
    svg
      .selectAll(".total-bar")
      .data(stackedData)
      .enter()
      .append("rect")
      .attr("class", "total-bar")
      .attr("x", (d) => x(d.label))
      .attr("y", (d) => y(d.end))
      .attr("width", x.bandwidth())
      .attr("height", (d) => chartHeight - y(d.value))
      .attr("fill", (d) => {
        if (d.type === "Environmental") return "#1f77b4"; // Blue
        if (d.type === "Social") return "#17becf"; // Cyan
        if (d.type === "Governance") return "#2ca02c"; // Green
        return "#888"; // Default grey
      });

    // Add labels inside the total stacked bar
    svg
      .selectAll(".total-label")
      .data(stackedData)
      .enter()
      .append("text")
      .attr("class", "total-label")
      .attr("x", (d) => x(d.label) + x.bandwidth() / 2)
      .attr("y", (d) => y(d.start + d.value / 2))
      .attr("text-anchor", "middle")
      .attr("fill", "#fff")
      .text((d) => d.value);

    // Add individual value labels inside each bar (not cumulative)
    svg
      .selectAll(".bar-label")
      .data(waterfallData)
      .enter()
      .append("text")
      .attr("class", "bar-label")
      .attr("x", (d) => x(d.label) + x.bandwidth() / 2)
      .attr("y", (d) => y(d.end) - 5)
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .text((d) => d.value); // Show individual values

    // Add the cumulative total value at the top of the stacked total bar
    svg
      .append("text")
      .attr("x", x("Total") + x.bandwidth() / 2)
      .attr("y", y(total) - 5)
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .text(total); // Show cumulative total at the top

    // Add a legend at the top
    const legend = svg
      .append("g")
      .attr("class", "legend")
      .attr("transform", `translate(0, -50)`);

    const categories = [
      { label: "Environmental", color: "#1f77b4" },
      { label: "Social", color: "#17becf" },
      { label: "Governance", color: "#2ca02c" },
    ];

    categories.forEach((category, i) => {
      const legendItem = legend.append("g").attr("transform", `translate(${i * 120}, 0)`);

      legendItem
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", 18)
        .attr("height", 18)
        .attr("fill", category.color);

      legendItem
        .append("text")
        .attr("x", 24)
        .attr("y", 9)
        .attr("dy", "0.35em")
        .attr("font-size", "12px")
        .text(category.label);
    });
  }, [data, width, height]);

  return <svg ref={svgRef}></svg>;
};

export default WaterfallChart;
