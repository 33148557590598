import React, { useState, useEffect } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifErrorMessage,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import "./DmaModal.scss";

const GlModal = ({
  onClose,
  title,
  handleSourceValueClick,
  modalMode,
  NameLabel,
  DescriptionLabel,
  cancelButtonLabel,
  saveButtonLabel,
  updateButtonLabel,
  selectedRow,
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [nameError, setNameError] = useState("");

  useEffect(() => {
    if (modalMode === "edit" && selectedRow) {
      setName(selectedRow.name);
      setDescription(selectedRow.description);
    } else {
      setName("");
      setDescription("");
    }
  }, [modalMode, selectedRow]);

  const validateFields = () => {
    let isValid = true;

    if (!name) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    return isValid;
  };

  const handleSubmit = () => {
    if (validateFields()) {
      const glData = {
        name,
        description,
      };
      handleSourceValueClick(glData);
    }
  };

  const isEditMode = modalMode === "edit";

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>{title}</h2>
        </div>
        <div className="modal-body">
          <div>
            <MotifLabel>{NameLabel}</MotifLabel>
            <MotifInput
              className="session-modal-input session-name-input"
              aria-describedby="session-name"
              aria-label={NameLabel}
              hideClearButton={true}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {nameError && <MotifErrorMessage>{nameError}</MotifErrorMessage>}

            <MotifLabel id="gl-select-label">{DescriptionLabel}</MotifLabel>
            <TextField
              placeholder=""
              multiline
              fullWidth
              rows={4}
              aria-label={DescriptionLabel}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
        <div className="modal-footer">
          <MotifButton onClick={onClose} className="button cancel-button">
            {cancelButtonLabel}
          </MotifButton>
          <MotifButton onClick={handleSubmit} className="button done-button">
            {isEditMode ? updateButtonLabel : saveButtonLabel}
          </MotifButton>
        </div>
      </div>
    </div>
  );
};

GlModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  handleSourceValueClick: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  NameLabel: PropTypes.string.isRequired,
  DescriptionLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  selectedRow: PropTypes.object,
};

export default GlModal;