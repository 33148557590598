import React, { useState } from "react";
import Stepper from "../../components/stepper/Stepper";
import Header from "../../components/headers/Header";
import {
  Stake,
  Beat,
  Iro,
  IroLite,
  ReportingLite,
  Reporting,
  BeatLite,
  StakeLite,
} from "../../constants/icons/Icons";
import "./DMA.scss";
import DMAOverview from "./dmaOverview/DMAOverview";
import DmaContext from "./dmaContext/DmaContext";
import DmaProcessSpecification from "./dmaProcessSpecification/DmaProcessSpecification";
import DMAReporting from "./dmaReporting/DMAReporting";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import DMAIroOverview from "./dmaIROAssessment/DMAIroOverview";
import DMAIroAssessment from "./dmaIROAssessment/DMAIroAssessment";

const DMA = () => {
  const [activeIndex, setActiveIndex] = useState(0); // Manage step index
  const [activeTab, setActiveTab] = useState("sources"); // Manage active tab state
  const [showAssessment, setShowAssessment] = useState(false);
  const [assessmentIroId, setAssessmentIroId] = useState(0);

  const { moduleId } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const projectId = location.state?.projectId;
  const token = location.state?.token;
  const projectName = location.state?.projectName;
  const sessionData = location.state?.sessionData;

  const handleStepChange = (index) => {
    setActiveIndex(index);
  };

  const switchToUnderstandTheContext = (tab) => {
    setActiveTab(tab);
    handleStepChange(1);
  };

  const getIcon = (index, activeIcon, inactiveIcon) => {
    return activeIndex === index ? activeIcon : inactiveIcon;
  };

  const project = sessionData?.find((project) =>
    project.modules.some((module) => module.id === parseInt(moduleId))
  );

  const stepItems = [
    { label: t("All projects"), link: "/" },
    { label: projectName, link: `/project-home/${projectId}`, token },
    { label: project?.name, link: `/project-home/${projectId}`, token },
    { label: t("DMA"), link: "#" },
  ];

  return (
    <div className="dma-page">
      <Header />
      <Stepper
        activeIndex={activeIndex} // Ensure synchronization with parent state
        onChange={handleStepChange} // Handle step changes
        items={stepItems}
      >
        <div icon={getIcon(0, <Beat />, <BeatLite />)} text={t("Overview")}>
          <DMAOverview onTabChange={switchToUnderstandTheContext} />
        </div>
        <div
          icon={getIcon(1, <Stake />, <StakeLite />)}
          text={t("UnderstandTheContext")}
        >
          <DmaContext activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>
        <div
          icon={getIcon(3, <Beat />, <BeatLite />)}
          text={t("ProcessSpecification")}
        >
          <DmaProcessSpecification />
        </div>
        <div
          icon={getIcon(4, <Iro />, <IroLite />)}
          text={t("IROIdentificationAndAssessment")}
        >
          {showAssessment ? (
            <DMAIroAssessment
              setShowAssessment={setShowAssessment}
              assessmentIroId={assessmentIroId}
            />
          ) : (
            <DMAIroOverview
              setShowAssessment={setShowAssessment}
              setAssessmentIroId={setAssessmentIroId}
            />
          )}
        </div>
        <div
          icon={getIcon(5, <Reporting />, <ReportingLite />)}
          text={t("ReportingImplication")}
        >
          <DMAReporting />
        </div>
      </Stepper>
    </div>
  );
};

export default DMA;
