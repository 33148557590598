import React, { useState, useEffect, useCallback, useRef } from "react";
import "./DMAIroAssessment.scss";
import MappingForm from "../../../components/forms/MappingForm";
import RateCard from "../../../components/rateCard/RateCard";
import { useTranslation } from "react-i18next";
import IROAssessmentSubheader from "../../../components/iroAssessmentSubheader/IROAssessmentSubheader";
import { Box, TableCell, IconButton } from "@mui/material";
import ClientTable from "../../../components/clientTable/ClientTable";
import ContextSelection from "../../../components/contextSelection/ContextSelection";
import { Edit } from "../../../constants/icons/Icons";
import TreeView from "../../../components/treeView/TreeView";
import { getAllSustainabilityMatters } from "../../../features/slices/SustainabilityMatters";
import {
  MotifCard,
  MotifCardHeader,
  MotifCardBody,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import ReferenceListTab from "../../../components/referenceListTab/ReferenceListTab";
import IroAssessmentModal from "../../../components/modals/dmaModals/IroAssessmentModal";
import { useLocation, useParams } from "react-router-dom";
import { fetchClientsDetails } from "../../../features/slices/ProjectScreens";
import { useDispatch } from "react-redux";
import {
  createIROAssessment,
  fetchIroModuleById,
  getAllModuleRating,
  getAPSRefList,
  getStakeholderRefList,
  getValueChainRefList,
  saveRACIMapping,
  getIROAssessment,
  updateIROAssessment,
  deleteIROAssessment,
} from "../../../features/slices/DMAIroAssessment";
import { fetchLikelihood } from "../../../features/slices/Likelihood";
import { getGeographiesIro } from "../../../features/slices/Iro";

const DMAIroAssessment = ({ setShowAssessment, assessmentIroId }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { moduleId: moduleIdString } = useParams();
  const moduleId = parseInt(moduleIdString);
  const [modal, setModal] = useState({ isOpen: false, title: "", mode: "add" });
  const [scoreData, setScoreData] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [smData, setSmData] = useState([]);
  const [IroModule, setIroModule] = useState([]);
  const [raciDropdownData, setRaciDropdownData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ratingDropDownArray, setRatingDropDownArray] = useState([]);
  const [ratingLikelihoodDropDownArray, setRatingLikelihoodDropDownArray] =
    useState([]);
  const [valueChain, setValueChain] = useState([]);
  const [aps, setAps] = useState([]);
  const [stakeholder, setStakeholder] = useState([]);
  const [geographies, setGeographies] = useState([]);
  const [infoRating, setInfoRating] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const [ratingCardScore, setRatingCardScore] = useState(null);
  const [ratingCardMaterial, setRatingCardMaterial] = useState(false);
  const [filteredUpdateData, setFilteredUpdateData] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updateItemID, setUpdateItemID] = useState(null);
  const [deleteItemID, setDeleteItemID] = useState(null);
  const token = location.state?.token;
  const projectId = location.state?.projectId;
  const [refreshData, setRefreshData] = useState(0);

  const moduleIROId = assessmentIroId;
  const iroId = assessmentIroId;

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [
        smResponse,
        clientResponse,
        getIroModuleResponse,
        valueChainReferenceResponse,
        apsReferenceResponse,
        stakeholderReferenceResponse,
        geographiesReferenceResponse,
        getIROAssessmentListResponse,
      ] = await Promise.all([
        dispatch(getAllSustainabilityMatters({ moduleId, token })),
        dispatch(fetchClientsDetails({ projectId, token })).unwrap(),
        dispatch(fetchIroModuleById({ moduleIROId, moduleId, token })).unwrap(),
        dispatch(
          getValueChainRefList({ moduleIROId, moduleId, token })
        ).unwrap(),
        dispatch(getAPSRefList({ moduleIROId, moduleId, token })).unwrap(),
        dispatch(
          getStakeholderRefList({ moduleIROId, moduleId, token })
        ).unwrap(),
        dispatch(getGeographiesIro({ iroId, token, moduleId })).unwrap(),
        dispatch(getIROAssessment({ moduleIROId, moduleId, token })).unwrap(),
      ]);

      setSmData(smResponse.payload.data || []);
      if (Array.isArray(clientResponse.data)) {
        const clientsData = clientResponse.data.map((item) => ({
          ...item,
          typeName: item.clientOrganisationType?.name || "Unknown",
        }));
        setRaciDropdownData(clientsData);
      } else {
        setRaciDropdownData([]);
      }
      if (getIroModuleResponse.data) {
        setIroModule(getIroModuleResponse.data?.moduleIRODto);
      } else {
        setIroModule([]);
      }

      if (valueChainReferenceResponse.data) {
        setValueChain(
          valueChainReferenceResponse.data.iroAssessmentValueChainServices
        );
      } else {
        setValueChain([]);
      }
      if (apsReferenceResponse.data) {
        setAps(apsReferenceResponse.data.iroAssessmentAPSServices);
      } else {
        setAps([]);
      }
      if (stakeholderReferenceResponse.data) {
        setStakeholder(
          stakeholderReferenceResponse.data.iroAssessmentStakeholdersServices
        );
      } else {
        setStakeholder([]);
      }
      if (geographiesReferenceResponse.data) {
        setGeographies(geographiesReferenceResponse.data);
      } else {
        setGeographies([]);
      }

      if (getIROAssessmentListResponse.data) {
        const modifiedArray =
          getIROAssessmentListResponse.data.iroAssessments.map((item) => ({
            ...item,
            isMaterial: item.isMaterial ? "Yes" : "No",
            isPosIsNeg: item.isPosIsNeg.split("/")[0],
          }));
        setScoreData(modifiedArray);
        setRatingCardScore(
          Math.floor(getIROAssessmentListResponse.data.maxScore * 10) / 10
        );
        setRatingCardMaterial(
          getIROAssessmentListResponse.data.isAssessmentMaterial
        );
      } else {
        setScoreData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, moduleId, moduleIROId, projectId, token, iroId]);

  useEffect(() => {
    fetchData();
  }, [fetchData,refreshData]);

  const onSave = async (formData) => {
    if (formData) {
      setIsLoading(true);
      try {
        const payload = {
          raciResponsibleId: formData.responsible,
          raciAccountableId: formData.accountable,
          raciConsultedId: formData.consulted,
          raciInformedId: formData.informed,
          raciOthersId: formData.others,
        };

        // Dispatch the saveRACIMapping action with the payload
        const response = await dispatch(
          saveRACIMapping({
            moduleIROId: moduleIROId,
            moduleId: moduleId,
            raciData: payload,
            token,
          })
        ).unwrap();

        if (response.success) {
          console.log("RACI mapping saved successfully:", response);
        } else {
          console.error("Failed to save RACI mapping:", response);
        }
      } catch (error) {
        console.error("Error while saving RACI mapping:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onCancel = async () => {
    setIsLoading(true);
    try {
      // Fetch the last saved RACI data
      const getIroModuleResponse = await dispatch(
        fetchIroModuleById({ moduleIROId, moduleId, token })
      ).unwrap();
      const lastSavedData = getIroModuleResponse?.data?.moduleIRODto;

      if (lastSavedData) {
        // Fetch client details to map IDs to names
        const clientResponse = await dispatch(
          fetchClientsDetails({ projectId, token })
        ).unwrap();
        const clientData = clientResponse?.data || [];

        // Helper function to get client name by ID
        const getClientById = (id) => {
          const client = clientData.find((item) => item.id === id);
          return client ? client.id : ""; // Return empty string if client is not found
        };

        // Set the initial form data with client names or IDs
        setInitialData({
          responsible: getClientById(lastSavedData?.raciResponsibleId),
          accountable: getClientById(lastSavedData?.raciAccountableId),
          consulted: getClientById(lastSavedData?.raciConsultedId),
          informed: getClientById(lastSavedData?.raciInformedId),
          others: getClientById(lastSavedData?.raciOthersId),
        });
      }
    } catch (error) {
      console.error("Error fetching RACI data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const columnsAssessment = [
    { colName: "isMaterial", label: t("Material"), showSorting: false },
    { colName: "valueChains", label: t("ValueChain"), showSorting: false },
    { colName: "score", label: t("Score"), showSorting: false },
    { colName: "isPosIsNeg", label: t("Type"), showSorting: false },
    { colName: "Action", label: t("Action"), showSorting: false },
  ];

  const findItemById = (data, id) => {
    for (let item of data) {
      if (item.assessment.id === id) return item;
      if (item.children) {
        const result = findItemById(item.children, id);
        if (result) return result;
      }
    }
    return null;
  };

  const renderTableCell = (colName, value, row, index) => {
    const handleEditClick = (mode) => {
      fetchRatingDropDownValues();
      setModal({
        isOpen: true,
        title: mode === "add" ? "New assessment" : "Edit assessment",
        mode: mode,
      });

      setFilteredUpdateData(findItemById(scoreData, row.assessment.id));
      setUpdateItemID(row.assessment.id);
      setDeleteItemID(row.assessment.id);
    };
    if (colName === "Action") {
      return (
        <TableCell
          key="actions"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => handleEditClick("edit")}>
              <Edit />
            </IconButton>
          </Box>
        </TableCell>
      );
    }
    return <TableCell key={index}>{value}</TableCell>;
  };

  const transformedItems = smData?.map((item) => ({
    id: item.id,
    itemId: item.id,
    label: item.label || item.name,
    children: item.children || [],
  }));

  const handleAddNew = (mode) => {
    setModal({
      isOpen: true,
      title: mode === "add" ? "New Assessment" : "Edit Assessment",
      mode: mode,
    });

    fetchRatingDropDownValues();
  };
  const fetchRatingDropDownValues = async () => {
    try {
      const [dropDownValueAssessment, dropDownValueLikelihood] =
        await Promise.all([
          dispatch(getAllModuleRating({ moduleId, token })).unwrap(),
          dispatch(fetchLikelihood({ moduleId, token })).unwrap(),
        ]);

      if (dropDownValueAssessment.data && dropDownValueLikelihood.data) {
        const combinedArray = [...dropDownValueAssessment.data];

        const likelihoodArray = dropDownValueLikelihood.data;

        const resultArray = [];
        combinedArray.forEach((item) =>
          resultArray.push({
            id: item.id,
            val: item.rating,
            label: item.likelihoodLabel || item.scaleLabel,
          })
        );

        const resultArrayLikelihood = [];
        likelihoodArray.forEach((item) =>
          resultArrayLikelihood.push({
            id: item.id,
            val: item.rating,
            label: item.likelihoodLabel || item.scaleLabel,
          })
        );
        setInfoRating(dropDownValueAssessment.data);
        setRatingDropDownArray(resultArray);
        setRatingLikelihoodDropDownArray(resultArrayLikelihood);
      } else {
        setRatingDropDownArray([]);
      }
    } catch (error) {
      console.error("Fetching of the data failed:", error);
    }
  };
  const handleCloseModal = () => {
    setModal((prev) => ({ ...prev, isOpen: false }));
  };


 const createNewIroAssessmentApi = async (data) => {
  if (data) {
    try {
      const response = await dispatch(
        createIROAssessment({ iroAssessmentData: data, token })
      ).unwrap();
      if (response.success) {
        handleCloseModal();
        setRefreshData((prev) => prev + 1); 
      }
    } catch (error) {
      console.error("Creation failed:", error);
    }
  }
};
 
const updateIroAssessmentApi = async (data) => {
  if (data) {
    try {
      const response = await dispatch(
        updateIROAssessment({ iroAssessmentData: data, token })
      ).unwrap();
      if (response.success) {
        handleCloseModal();
        setRefreshData((prev) => prev + 1); 
      }
    } catch (error) {
      console.error("Updating failed:", error);
    }
  }
};

  const handleDeleteCloseModalTry = () => {
    setIsDeleteModalOpen(false);
  };

  const childRef = useRef();

  const handleDelete = async () => {
    try {
      await dispatch(
        deleteIROAssessment({
          assessmentId: deleteItemID,
          moduleIROId,
          moduleId,
          token,
        })
      ).unwrap();
      handleDeleteCloseModalTry();
      setUpdateTrigger((prev) => prev + 1);
      if (childRef.current) {
        childRef.current.handleDeleteCloseModal();
      }
    } catch (error) {
      console.error("Deletion failed:", error);
    }
  };

  const onBackClick = () => {
    setShowAssessment(false);
  };

  return (
    <div className="iro-assessment-container">
      <div className="iro-subheader">
        <IROAssessmentSubheader
          header={IroModule.name || ""}
          subHeader={(IroModule.pillar || "") + " " + (IroModule.name || "")}
          impact={IroModule.iroTypeName || ""}
          previousButtonLabel={t("Previous")}
          nextButtonLabel={t("Next")}
          onBackClick={onBackClick}
        />
      </div>
      <div className="motif-container iro-section">
        <div className="motif-row">
          <div className="motif-col-8 motif-col-xl-8 iro-left-section">
            <RateCard
              ratingCardValue={ratingCardScore}
              ratingCardHeader={"IRO assessment"}
              ratingCardLabel={ratingCardMaterial ? "Material" : "Non Material"}
            />

            <MotifCard className="assessment-card">
              <MotifCardHeader className="assessment-header" variant="alt">
                {t("Assessments")}
              </MotifCardHeader>
              <MotifCardBody>
                <ContextSelection
                  addButtonText={t("Add New")}
                  onClickNewProject={() => handleAddNew("add")}
                  pageName={t("Assessments")}
                />

                <div className="gl-card-table-switch assessment-table">
                  {modal.isOpen && (
                    <IroAssessmentModal
                      iroType={"Impact"}
                      modalTitle={modal.title}
                      modalMode={modal.mode}
                      saveButtonLabel={t("Save")}
                      updateButtonLabel={t("Update")}
                      cancelButtonLabel={t("Cancel")}
                      onClose={handleCloseModal}
                      ratingDropDownArray={ratingDropDownArray}
                      likelihood={ratingLikelihoodDropDownArray}
                      valueChainDropDown={valueChain}
                      handleDoneClick={
                        modal.mode === "add"
                          ? createNewIroAssessmentApi
                          : updateIroAssessmentApi
                      }
                      deleteButtonLabel={t("Delete")}
                      ratingLabel={t("RatingLabel")}
                      descriptionLabel={t("Description")}
                      iroTypeLabel={t("IroTypeLabel")}
                      valueChainLabel={t("ValueChain")}
                      positiveNegativeLabel={t("PositiveNegative")}
                      actualPotentialLabel={t("ActualPotential")}
                      filteredUpdateData={filteredUpdateData}
                      moduleId={moduleId}
                      moduleIROId={moduleIROId}
                      handleDelete={handleDelete}
                      infoRating={infoRating}
                      closeModal={isDeleteModalOpen}
                      ref={childRef}
                    />
                  )}
                  <ClientTable
                    columns={columnsAssessment}
                    data={scoreData}
                    itemsPerPage={10}
                    renderTableCell={renderTableCell}
                    generateRowKey={(row) => row.id}
                  />
                </div>
              </MotifCardBody>
            </MotifCard>
          </div>
          <div className="motif-col-4 motif-col-xl-4 iro-right-section">
            <MotifCard className="sm-card">
              <MotifCardHeader className="assessment-header" variant="alt">
                {t("Sustainablilitymatters")}
              </MotifCardHeader>
              <MotifCardBody>
                <TreeView
                  items={transformedItems}
                  pageName="IROA"
                  defaultExpandedItems={[]}
                  defaultSelectedItems="1"
                />
              </MotifCardBody>
            </MotifCard>
            <ReferenceListTab
              cardHeader={t("Reference lists")}
              valueChain={valueChain}
              aps={aps}
              stakeholder={stakeholder}
              geographies={geographies}
            />
            <MappingForm
              responsibleLabel={t("Responsible")}
              accountableLabel={t("Accountable")}
              consultedLabel={t("Consulted")}
              informedLabel={t("Informed")}
              othersLabel={t("Others")}
              selectResponsilbleLabel={t("SelectResponsible")}
              selectAccountableLabel={t("SelectAccountable")}
              selectConsultedLabel={t("SelectConsulted")}
              selectInformedLabel={t("SelectInformed")}
              selectOthersLabel={t("SelectOthers")}
              RACIMapping={t("RACIMapping")}
              saveButtonLabel={t("Save")}
              cancelButtonLabel={t("Cancel")}
              onSave={onSave}
              onCancel={onCancel}
              raciDropdownData={raciDropdownData}
              initialData={initialData}
            />
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="iro-motif-loader">
          <MotifProgressLoader className="loader" show variant="default" />
        </div>
      )}
    </div>
  );
};

export default DMAIroAssessment;
