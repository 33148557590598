import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import {
  createNewSustainabilityMatters,
  deleteSustainabilityMatters,
  fetchEsrsAr16,
  getAllSustainabilityMatters,
  updateSustainabilityMatters,
} from "../../../../features/slices/SustainabilityMatters";
import { fetchExcelData } from "../../../../features/slices/LongListData";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import ContextSelection from "../../../../components/contextSelection/ContextSelection";
import TreeView from "../../../../components/treeView/TreeView";
import SustainabilityMattersModal from "../../../../components/modals/dmaModals/SustainabilityMattersModal";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";
import ErrorModal from "../../../../components/modals/error/errorModal";
import "./SustainabillityMatters.scss";
import { useTranslation } from "react-i18next";
const SustainabilityMatters = () => {
  const [modal, setModal] = useState({ isOpen: false, title: "", mode: "add" });
  const [options, setOptions] = useState({
    parent: [],
    esrs: [],
    esrsar16: [],
  });
  const [filteredUpdateData, setFilteredUpdateData] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updateItemID, setUpdateItemID] = useState(null);
  const [deleteItemID, setDeleteItemID] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = location.state?.token;
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const [smData, setSmData] = useState([]);

  const getEligibleParentOptions = useCallback(
    (items, parentId = null, currentLevel = 0) => {
      if (currentLevel >= 2) return []; // Stop recursion if level exceeds 2

      return items
        .filter((item) => item.parentId === parentId)
        .flatMap((item) => [
          {
            value: item.id,
            label: item.label || item.name,
          },
          ...getEligibleParentOptions(
            item.children || [],
            item.id,
            currentLevel + 1
          ), // Recursively get children if present
        ]);
    },
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [smResponse, esrsar16Response] = await Promise.all([
          dispatch(getAllSustainabilityMatters({ moduleId, token })),
          dispatch(fetchEsrsAr16({ token })),
        ]);
        const [excelResponse] = await Promise.all([
          dispatch(fetchExcelData({ moduleId, token })),
        ]);
        const exceResponseData = excelResponse.payload.data || [];
        setExcelData(exceResponseData);
        const sustainabilityMatters = smResponse.payload.data || [];
        setSmData(sustainabilityMatters);
        setOptions({
          esrsar16:
            esrsar16Response.payload.data.map((type) => ({
              value: type.id,
              label: type.name,
            })) || [],
          parent: getEligibleParentOptions(sustainabilityMatters),
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, moduleId, updateTrigger, token, getEligibleParentOptions]);

  const sustainabilityMattersState = useSelector(
    (state) => state.dmaSustainabilityMatters
  );
  const { isError, errorMessage } = sustainabilityMattersState;

  const handleOpenModal = (mode, itemId) => {
    setModal({
      isOpen: true,
      mode,
      title:
        mode === "add"
          ? t("newSustainabilityMatters")
          : t("editSustainabilityMatters"),
    });
  };

  const handleCloseModal = () => {
    setModal((prev) => ({ ...prev, isOpen: false }));
    setUpdateItemID(null);
  };

  const findItemById = (data, id) => {
    for (let item of data) {
      if (item.id === id) return item;
      if (item.children) {
        const result = findItemById(item.children, id);
        if (result) return result;
      }
    }
    return null;
  };

  const handleEditClick = (id) => {
    handleOpenModal("edit", id);
    setFilteredUpdateData(findItemById(smData, id));
    setUpdateItemID(id);
    setDeleteItemID(id);
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      await dispatch(
        deleteSustainabilityMatters({
          sustainabilityMattersId: deleteItemID,
          moduleId,
          token,
        })
      ).unwrap();
      handleCloseModal();
      handleDeleteCloseModal();
      setUpdateTrigger((prev) => prev + 1);
    } catch (error) {
      console.error("Deletion failed:", error);
    }
  };

  function findParentById(data, parentId) {
    for (let item of data) {
      if (item.id === parentId) {
        return item;
      }
      if (item.children && item.children.length > 0) {
        const found = findParentById(item.children, parentId);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  const createNewSustainabilityMattersApi = async (data) => {
    if (data) {
      try {
        let newSortOrder;
        if (data.parentId === null) {
          const maxSortOrder = smData.reduce(
            (max, item) => (item.sortOrder > max ? item.sortOrder : max),
            0
          );
          newSortOrder = maxSortOrder + 1;
        } else {
          const parentItem = findParentById(smData, data.parentId);

          newSortOrder = parentItem ? parentItem.sortOrder : 0;
        }
        const newData = {
          ...data,
          sortOrder: newSortOrder,
        };

        await dispatch(
          createNewSustainabilityMatters({
            moduleId,
            sustainabilityMattersData: newData,
            token,
          })
        ).unwrap();

        setUpdateTrigger((prev) => prev + 1);
        handleCloseModal();
      } catch (error) {
        console.error("Creation failed:", error);
      }
    } else {
      alert("Please fill in all required fields");
    }
  };

  const updateSustainabilityMattersApi = async (data) => {
    if (data) {
      setIsLoading(true);
      try {
        const existingItem = findItemById(smData, updateItemID);
        const updatedData = {
          ...data,
          sortOrder: existingItem.sortOrder,
        };

        const response = await dispatch(
          updateSustainabilityMatters({
            sustainabilityMattersId: updateItemID,
            sustainabilityMattersData: updatedData,
            moduleId,
            token,
          })
        ).unwrap();

        if (response.success) {
          handleCloseModal();
          setUpdateTrigger((prev) => prev + 1);
        }
      } catch (error) {
        console.error("Update failed:", error);
      }
      setIsLoading(false);
    } else {
      alert("Please fill in all required fields");
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event);
  };

  const filterItems = (items, query) => {
    if (!query) return items;
    return items
      .map((item) => {
        if (
          item?.label?.toLowerCase().includes(query.toLowerCase()) ||
          item?.description?.toLowerCase().includes(query.toLowerCase())
        )
          return item;
        if (item.children) {
          const filteredChildren = filterItems(item.children, query);
          if (filteredChildren.length > 0)
            return { ...item, children: filteredChildren };
        }
        return null;
      })
      .filter(Boolean);
  };

  const formatExcelData = (data) => {
    const workbook = XLSX.utils.book_new();

    const addSheet = (sheetName, data) => {
      // Gather unique names for each section
      const uniqueSourceTypes = Array.from(
        new Set(
          data.flatMap((item) =>
            item.sources.map((source) => source.sourceType)
          )
        )
      );
      const uniqueSourceNames = Array.from(
        new Set(
          data.flatMap((item) => item.sources.map((source) => source.name))
        )
      );
      const uniqueValueChainNames = Array.from(
        new Set(data.flatMap((item) => item.valueChain.map((vc) => vc.name)))
      );
      const uniqueStakeholderNames = Array.from(
        new Set(data.flatMap((item) => item.stakeholders.map((st) => st.name)))
      );
      const uniqueGeographyNames = Array.from(
        new Set(data.flatMap((item) => item.geographies.map((geo) => geo.name)))
      );
      const uniqueActProServiceNames = Array.from(
        new Set(
          data.flatMap((item) => item.actProServices.map((aps) => aps.name))
        )
      );

      // Top-level headers
      const headers = [
        "Section",
        "ESRS",
        "Topic",
        "SubTopic",
        "SubSubTopic",
        ...Array(uniqueSourceTypes.length * uniqueSourceNames.length).fill(
          "Sources"
        ),
        ...Array(uniqueValueChainNames.length).fill("Value Chain"),
        ...Array(uniqueStakeholderNames.length).fill("Stakeholders"),
        ...Array(uniqueGeographyNames.length).fill("Geographies"),
        ...Array(uniqueActProServiceNames.length).fill("ActProServices"),
      ];

      // Sub-headers with individual names
      const subHeaders = [
        "Section",
        "ESRS",
        "Topic",
        "SubTopic",
        "SubSubTopic",
        ...uniqueSourceTypes.flatMap((type) =>
          uniqueSourceNames.map((name) => `${type} - ${name}`)
        ),
        ...uniqueValueChainNames,
        ...uniqueStakeholderNames,
        ...uniqueGeographyNames,
        ...uniqueActProServiceNames,
      ];

      // Start worksheet data with headers and sub-headers
      const worksheetData = [headers, subHeaders];

      // Populate rows based on item data
      data.forEach((item) => {
        const row = [
          item.section,
          item.esrs,
          item.topic,
          item.subTopic,
          item.subSubTopic,
        ];

        // For each source, mark "X" if active
        uniqueSourceTypes.forEach((type) => {
          uniqueSourceNames.forEach((name) => {
            const isActive = item.sources.some(
              (src) =>
                src.sourceType === type && src.name === name && src.isActive
            );
            row.push(isActive ? "X" : "");
          });
        });

        // For each value chain, mark "X" if active
        uniqueValueChainNames.forEach((name) => {
          const isActive = item.valueChain.some(
            (vc) => vc.name === name && vc.isActive
          );
          row.push(isActive ? "X" : "");
        });

        // For each stakeholder, mark "X" if active
        uniqueStakeholderNames.forEach((name) => {
          const isActive = item.stakeholders.some(
            (st) => st.name === name && st.isActive
          );
          row.push(isActive ? "X" : "");
        });

        // For each geography, mark "X" if active
        uniqueGeographyNames.forEach((name) => {
          const isActive = item.geographies.some(
            (geo) => geo.name === name && geo.isActive
          );
          row.push(isActive ? "X" : "");
        });

        // For each ActProService, mark "X" if active
        uniqueActProServiceNames.forEach((name) => {
          const isActive = item.actProServices.some(
            (aps) => aps.name === name && aps.isActive
          );
          row.push(isActive ? "X" : "");
        });

        worksheetData.push(row);
      });

      // Create worksheet and add it to the workbook
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

      // Merge cells for top-level headers
      const sourcesStartCol = 5;
      const sourcesEndCol =
        sourcesStartCol +
        uniqueSourceTypes.length * uniqueSourceNames.length -
        1;
      const valueChainStartCol = sourcesEndCol + 1;
      const valueChainEndCol =
        valueChainStartCol + uniqueValueChainNames.length - 1;
      const stakeholdersStartCol = valueChainEndCol + 1;
      const stakeholdersEndCol =
        stakeholdersStartCol + uniqueStakeholderNames.length - 1;
      const geographiesStartCol = stakeholdersEndCol + 1;
      const geographiesEndCol =
        geographiesStartCol + uniqueGeographyNames.length - 1;
      const actProServicesStartCol = geographiesEndCol + 1;
      const actProServicesEndCol =
        actProServicesStartCol + uniqueActProServiceNames.length - 1;

      worksheet["!merges"] = [
        { s: { r: 0, c: sourcesStartCol }, e: { r: 0, c: sourcesEndCol } }, // Merge "Sources" header
        {
          s: { r: 0, c: valueChainStartCol },
          e: { r: 0, c: valueChainEndCol },
        }, // Merge "Value Chain" header
        {
          s: { r: 0, c: stakeholdersStartCol },
          e: { r: 0, c: stakeholdersEndCol },
        }, // Merge "Stakeholders" header
        {
          s: { r: 0, c: geographiesStartCol },
          e: { r: 0, c: geographiesEndCol },
        }, // Merge "Geographies" header
        {
          s: { r: 0, c: actProServicesStartCol },
          e: { r: 0, c: actProServicesEndCol },
        }, // Merge "ActProServices" header
      ];

      // Set column widths for better visibility
      worksheet["!cols"] = Array(headers.length).fill({ wpx: 100 });

      return worksheet;
    };

    // Format data and add sheet to workbook
    addSheet("Sustainability Matters", data);

    return workbook;
  };

  // Function to trigger Excel download
  const downloadExcel = (data) => {
    const workbook = formatExcelData(data);
    XLSX.writeFile(workbook, "sustainability_matters.xlsx");
  };

  const handleDownloadExcel = () => {
    downloadExcel(excelData);
  };
  const transformedItems =
    smData.map((item) => ({
      id: item.id,
      itemId: item.id,
      label: item.label || item.name,
      children: item.children || [],
    })) || [];

  const filteredItems = filterItems(transformedItems, searchInput);

  const handleError = () => {
    navigate("/");
  };

  if (isError) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessage}
          handleButtonClick={handleError}
          deleteButtonLabel={t("Reload")}
        />
      </div>
    );
  }
  console.log("Exceldata", excelData);
  return (
    <>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      {!isLoading && (
        <div>
          <ContextSelection
            addButtonText="Add new"
            pageName="SM"
            downloadButtonText="Download longlist"
            onClickNewProject={() => handleOpenModal("add")}
            onClickNewDownload={handleDownloadExcel}
            onChangeSearchInput={handleSearchInputChange}
          />
        </div>
      )}
      <div className="sm-file-explorer">
        <TreeView
          items={filteredItems}
          pageName="SM"
          defaultExpandedItems={[]}
          defaultSelectedItems="1"
          onEditClick={handleEditClick}
        />
      </div>
      {modal.isOpen && (
        <SustainabilityMattersModal
          onClose={handleCloseModal}
          modalTitle={modal.title}
          modalMode={modal.mode}
          topicNameLabel={t("TopicName")}
          parentLabel={t("Parent")}
          esrsAr16Label={t("ESRS_AR16")}
          esgLabel={t("ESG")}
          enterTopicNameLabel={t("EnterTopicName")}
          selectParentLabel={t("SelectParent")}
          isHumanRightsRelatedLabel={t("isHumanRightsRelated")}
          selectEsrsAr16Label={t("SelectESRS")}
          DescriptionLabel={t("Description")}
          saveButtonLabel={t("addSustainabilityMatters")}
          updateButtonLabel={t("Update")}
          cancelButtonLabel={t("Cancel")}
          esrsar16Options={options.esrsar16}
          parentOptions={options.parent}
          handleDoneClick={
            modal.mode === "add"
              ? createNewSustainabilityMattersApi
              : updateSustainabilityMattersApi
          }
          filteredUpdateData={filteredUpdateData}
          onDeleteClick={handleDeleteClick}
          DeleteLabel={t("DeleteLabel")}
          updateItemID={updateItemID}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={handleDeleteCloseModal}
          setName={t("DeleteLabel")}
          labelText={t("DeletConfirmationSources")}
          onCancel={handleDeleteCloseModal}
          handleDeleteClick={handleDelete}
          cancelButtonLabel={t("Cancel")}
          deleteButtonLabel={t("DeleteLabel")}
        />
      )}
    </>
  );
};

export default SustainabilityMatters;