import React, {
  useEffect,
  useReducer,
  useCallback,
  useMemo,
  useState,
  useRef,
} from "react";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import "./ProjectHome.scss";
import Header from "../../components/headers/Header";
import SessionModulelanguageData from "../projectHome/sessionAccordion/Session_Module.json";
import Accordion from "../projectHome/sessionAccordion/SessionAccordion";
import ProjectDetails from "./projectDetails/ProjectDetails";
import Subheader from "../../components/subheader/Subheader";
import languageData from "../../constants/languages/en/translations.json";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import AddUser from "../../components/modals/addUser/AddUser";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProjectDetails,
  createSessionInProject,
  createModuleInProject,
  updateSessionDetails,
  retireSession,
  fetchAllModuleTypes,
  fetchModuleById,
  updateModule,
  getAllSessionFrameWork,
} from "../../features/slices/ProjectScreens";
import ErrorModal from "../../components/modals/error/errorModal";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import {
  setUser,
  getPhoto,
  setJwtToken,
} from "../../features/slices/HomeSlice";

const TOKEN_REFRESH_INTERVAL = 15 * 60 * 1000; // 15 minutes
const TOKEN_EXPIRY_TIME = 30 * 60 * 1000; // 30 minutes

const initialState = {
  isModalOpen: false,
  isModuleModalOpen: false,
  sessionName: "",
  sessionFramework: "",
  modalType: "",
  searchQuery: "",
  sessionId: "",
  updateTrigger: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_IS_MODAL_OPEN":
      return { ...state, isModalOpen: action.payload };
    case "SET_IS_MODULE_MODAL_OPEN":
      return { ...state, isModuleModalOpen: action.payload };
    case "SET_SESSION_NAME":
      return { ...state, sessionName: action.payload };
    case "SET_SESSION_FRAMEWORK":
      return { ...state, sessionFramework: action.payload };
    case "SET_MODAL_TYPE":
      return { ...state, modalType: action.payload };
    case "SET_SEARCH_QUERY":
      return { ...state, searchQuery: action.payload };
    case "SET_SESSION_ID":
      return { ...state, sessionId: action.payload };
    case "INCREMENT_UPDATE_TRIGGER":
      return { ...state, updateTrigger: state.updateTrigger + 1 };
    default:
      return state;
  }
};

const ProjectHome = () => {
  const [state, dispatchLocal] = useReducer(reducer, initialState);
  const isAdmin = true;
  const location = useLocation();
  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { projectId: projectIdString } = useParams();
  const projectId = parseInt(projectIdString);
  const [moduleTypes, setModuleTypes] = useState([]);
  const [moduleDataById, setModuleDataById] = useState();
  const [sessionFrameWorkDropDown, setSessionFrameWorkDropDown] = useState([]);
  const tokenRefreshIntervalRef = useRef(null);
  const inactivityTimeoutRef = useRef(null);
  const [token, setToken] = useState(location.state?.token || null);
  const { data, isError, isLoading, errorMessage } = useSelector(
    (state) => state.projectDetails
  );
  const projectData = useMemo(() => data?.data || {}, [data]);
  const sessionsData = useMemo(
    () => projectData.sessions || SessionModulelanguageData,
    [projectData]
  );
  const projectName = projectData?.name;

  const serializeAccount = useCallback((account) => {
    return {
      ...account,
      tenantProfiles: account.tenantProfiles
        ? Object.fromEntries(account.tenantProfiles)
        : {},
    };
  }, []);

  const refreshToken = useCallback(async () => {
    if (instance && accounts.length > 0) {
      try {
        const tokenResponse = await instance.acquireTokenSilent({
          scopes: ["https://database.windows.net/user_impersonation"],
          account: accounts[0],
        });
        const newToken = tokenResponse.accessToken;
        sessionStorage.setItem("JwtToKen", newToken);
        dispatch(setJwtToken(newToken));
        setToken(newToken);
      } catch (error) {
        console.error("Failed to refresh token", error);
      }
    }
  }, [instance, accounts, dispatch]);

  const expireToken = useCallback(() => {
    console.warn("Token expired due to inactivity");
    sessionStorage.removeItem("JwtToKen");
    dispatch(setJwtToken(null));
    setToken(null);
    navigate("/");
  }, [dispatch, navigate]);

  const resetInactivityTimer = useCallback(() => {
    if (inactivityTimeoutRef.current) {
      clearTimeout(inactivityTimeoutRef.current);
    }
    inactivityTimeoutRef.current = setTimeout(expireToken, TOKEN_EXPIRY_TIME);
  }, [expireToken]);

  const initializeAuth = useCallback(async () => {
    if (!instance) {
      console.error("MSAL instance is not initialized");
      return;
    }
    try {
      let token;
      if (accounts.length === 0 && inProgress === "none") {
        const loginResponse = await instance.loginRedirect({
          scopes: ["https://database.windows.net/user_impersonation"],
        });
        token = loginResponse.accessToken;
        const serializedAccount = serializeAccount(loginResponse.account);
        sessionStorage.setItem("JwtToKen", token);
        dispatch(setUser(serializedAccount));
        dispatch(setJwtToken(token));
        dispatch(getPhoto());
      } else if (accounts.length > 0) {
        const tokenResponse = await instance.acquireTokenSilent({
          scopes: ["https://database.windows.net/user_impersonation"],
          account: accounts[0],
        });
        token = tokenResponse.accessToken;
        const serializedAccount = serializeAccount(accounts[0]);
        sessionStorage.setItem("JwtToKen", token);
        dispatch(setUser(serializedAccount));
        dispatch(setJwtToken(token));
        dispatch(getPhoto());
      }

      if (token) {
        setToken(token);
        dispatch(fetchProjectDetails({ projectId, token }));
      }
    } catch (error) {
      console.error("Authentication failed", error);
    }
  }, [instance, accounts, inProgress, dispatch, serializeAccount, projectId]);

  useEffect(() => {
    if (!token) {
      initializeAuth();
    } else {
      dispatch(fetchProjectDetails({ projectId, token }));
    }

    tokenRefreshIntervalRef.current = setInterval(
      refreshToken,
      TOKEN_REFRESH_INTERVAL
    );
    window.addEventListener("mousemove", resetInactivityTimer);
    window.addEventListener("keydown", resetInactivityTimer);
    resetInactivityTimer();

    return () => {
      clearInterval(tokenRefreshIntervalRef.current);
      clearTimeout(inactivityTimeoutRef.current);
      window.removeEventListener("mousemove", resetInactivityTimer);
      window.removeEventListener("keydown", resetInactivityTimer);
    };
  }, [
    token,
    initializeAuth,
    refreshToken,
    resetInactivityTimer,
    dispatch,
    projectId,
  ]);

  const fetchSessionFrameWork = useCallback(async () => {
    try {
      const response = await dispatch(
        getAllSessionFrameWork({ token })
      ).unwrap();
      if (response.success) {
        setSessionFrameWorkDropDown(response.data.frameworks || []);
      }
    } catch (error) {
      console.error("Fetching of session frameworks failed:", error);
    }
  }, [dispatch, token]);

  const handleSessionModal = useCallback(async () => {
    await fetchSessionFrameWork();
    dispatchLocal({ type: "SET_IS_MODAL_OPEN", payload: true });
    dispatchLocal({
      type: "SET_MODAL_TYPE",
      payload: languageData.AddSessionPopup,
    });
  }, [fetchSessionFrameWork]);

  const fetchModuleTypes = useCallback(async () => {
    try {
      const response = await dispatch(fetchAllModuleTypes({ token })).unwrap();
      if (response.success) {
        setModuleTypes(response.data);
      }
    } catch (error) {
      console.error("Fetching of module types failed:", error);
    }
  }, [dispatch, token]);

  const fetchModuleByID = useCallback(
    async (id) => {
      try {
        const response = await dispatch(
          fetchAllModuleTypes({ token })
        ).unwrap();
        if (response.success) {
          setModuleTypes(response.data);
        }
      } catch (error) {
        console.error("Fetching of module types failed:", error);
      }
      try {
        const response = await dispatch(
          fetchModuleById({ id, token })
        ).unwrap();
        if (response.success) {
          setModuleDataById(response.data);
        }
      } catch (error) {
        console.error("Fetching of module types failed:", error);
      }
    },
    [dispatch, token]
  );

  const handleModuleModal = useCallback(
    async (sessionName, id) => {
      await fetchModuleTypes();
      dispatchLocal({ type: "SET_IS_MODULE_MODAL_OPEN", payload: true });
      dispatchLocal({ type: "SET_SESSION_NAME", payload: sessionName });
      dispatchLocal({ type: "SET_SESSION_ID", payload: id });
      dispatchLocal({
        type: "SET_MODAL_TYPE",
        payload: languageData.AddModulePopup,
      });
    },
    [fetchModuleTypes]
  );

  const handleCloseModal = useCallback(() => {
    dispatchLocal({ type: "SET_IS_MODAL_OPEN", payload: false });
    dispatchLocal({ type: "SET_SESSION_NAME", payload: "" });
    dispatchLocal({ type: "SET_SESSION_FRAMEWORK", payload: "" });
  }, []);

  const handleModuleCloseModal = useCallback(() => {
    dispatchLocal({ type: "SET_IS_MODULE_MODAL_OPEN", payload: false });
    dispatchLocal({ type: "SET_SESSION_NAME", payload: "" });
    dispatchLocal({ type: "SET_SESSION_FRAMEWORK", payload: "" });
  }, []);

  //New functions for handling edit and delete session modals
  const handleEditSessionModal = useCallback(
    async (sessionId, sessionName, sessionFramework) => {
      dispatchLocal({ type: "SET_IS_MODAL_OPEN", payload: true });
      dispatchLocal({
        type: "SET_MODAL_TYPE",
        payload: languageData.EditSessionPopup,
      });
      dispatchLocal({ type: "SET_SESSION_ID", payload: sessionId });
      dispatchLocal({ type: "SET_SESSION_NAME", payload: sessionName });
      dispatchLocal({
        type: "SET_SESSION_FRAMEWORK",
        payload: sessionFramework,
      });
    },
    []
  );

  const handleDeleteSessionModal = useCallback((sessionId, sessionName) => {
    dispatchLocal({ type: "SET_IS_MODAL_OPEN", payload: true });
    dispatchLocal({
      type: "SET_MODAL_TYPE",
      payload: languageData.RetireSessionPopup,
    });
    dispatchLocal({ type: "SET_SESSION_ID", payload: sessionId });
    dispatchLocal({ type: "SET_SESSION_NAME", payload: sessionName });
  }, []);

  // New functions for handling edit module  modal

  const handleEditModuleModal = useCallback(
    async (id, sessionName) => {
      await fetchModuleByID(id);

      dispatchLocal({ type: "SET_IS_MODULE_MODAL_OPEN", payload: true });
      dispatchLocal({ type: "SET_SESSION_NAME", payload: sessionName });
      dispatchLocal({ type: "SET_SESSION_ID", payload: id });
      dispatchLocal({
        type: "SET_MODAL_TYPE",
        payload: languageData.EditModulePopup,
      });
    },
    [fetchModuleByID]
  );

  const createSession = useCallback(
    async (sessionData) => {
      if (sessionData.name && sessionData.frameworkId) {
        sessionData.projectId = parseFloat(projectId);
        dispatch(createSessionInProject({ sessionData, token }))
          .unwrap()
          .then((response) => {
            if (response.success) {
              dispatchLocal({ type: "INCREMENT_UPDATE_TRIGGER" });
              handleCloseModal();
              dispatch(fetchProjectDetails({ projectId, token }));
            }
          })
          .catch((error) => {
            console.error("creation failed:", error);
          });
      } else {
        alert("session name and framework creation failed");
      }
    },
    [dispatch, projectId, token, handleCloseModal]
  );

  const updateSession = useCallback(
    async (sessionData) => {
      if (sessionData.name && sessionData.id) {
        dispatch(
          updateSessionDetails({
            name: sessionData.name,
            id: sessionData.id,
            token,
            projectId,
          })
        )
          .unwrap()
          .then((response) => {
            if (response.success) {
              dispatchLocal({ type: "INCREMENT_UPDATE_TRIGGER" });
              handleCloseModal();
              dispatch(fetchProjectDetails({ projectId, token }));
            }
          })
          .catch((error) => {
            console.error("Update failed:", error);
          });
      } else {
        alert("Please fill in all required fields");
      }
    },
    [dispatch, token, handleCloseModal, projectId]
  );

  const handleRetireSession = useCallback(
    async (sessionData) => {
      if (sessionData.id) {
        dispatch(retireSession({ id: sessionData.id, token, projectId }))
          .unwrap()
          .then((response) => {
            if (response) {
              alert("Session retired successfully");
              dispatchLocal({ type: "INCREMENT_UPDATE_TRIGGER" });
              handleCloseModal();
              dispatch(fetchProjectDetails({ projectId, token }));
            }
          })
          .catch((error) => {
            console.error("Retire failed:", error);
          });
      } else {
        alert("Please fill in all required fields");
      }
    },
    [dispatch, token, handleCloseModal, projectId]
  );

  const createModule = useCallback(
    (moduleData) => {
      if (moduleData.sessionId) {
        dispatch(createModuleInProject({ moduleData, token }))
          .unwrap()
          .then((response) => {
            if (response.success) {
              dispatchLocal({ type: "INCREMENT_UPDATE_TRIGGER" });
              handleModuleCloseModal();
              dispatch(fetchProjectDetails({ projectId, token }));
            }
          })
          .catch((error) => {
            console.error("creation failed:", error);
          });
      } else {
        alert("Please fill in all required fields");
      }
    },
    [dispatch, token, handleModuleCloseModal, projectId]
  );

  const updateModuleId = useCallback(
    async (moduleDataEdited) => {
      if (moduleDataEdited) {
        dispatch(updateModule({ moduleDataEdited, token }))
          .unwrap()
          .then((response) => {
            if (response.success) {
              dispatchLocal({ type: "INCREMENT_UPDATE_TRIGGER" });
              handleModuleCloseModal();
              dispatch(fetchProjectDetails({ projectId, token }));
            }
          })
          .catch((error) => {
            console.error("Update failed:", error);
          });
      } else {
        alert("Please fill in all required fields");
      }
    },
    [dispatch, token, projectId, handleModuleCloseModal]
  );

  const handleError = () => {
    navigate("/");
  };
  const handleClientOrg = useCallback(() => {
    navigate(`/client-org/${projectId}`, { state: { projectName, token } });
  }, [navigate, projectId, token, projectName]);

  const handleUserManagement = useCallback(() => {
    navigate(`/user-management/${projectId}`, {
      state: { projectName, token },
    });
  }, [navigate, projectId, token, projectName]);

  const handleFileManager = useCallback(() => {
    navigate(`/file-manager/${projectId}`, { state: { projectName, token } });
  }, [navigate, projectId, token, projectName]);

  const handleEditNavigate = useCallback(() => {
    navigate(`/edit-project/${projectId}`, { state: { projectName, token } });
  }, [navigate, projectId, token, projectName]);

  const loggedInUserEmail = accounts[0]?.username;
  const isUserAdmin = useMemo(() => {
    return data?.data?.users?.some(
      (user) =>
        user.user.email === loggedInUserEmail &&
        user.role.name === "Project admin"
    );
  }, [data, loggedInUserEmail]);
  const handleSearch = useCallback((event) => {
    dispatchLocal({ type: "SET_SEARCH_QUERY", payload: event });
  }, []);
  if (isError) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessage}
          handleButtonClick={handleError}
          deleteButtonLabel={t("Reload")}
        />
      </div>
    );
  }
  return (
    <div>
      <Header languageData-testid="header" />
      <div className="Container">
        <Breadcrumbs
          items={[
            { label: "All projects", link: "/" },
            {
              label: projectData.name,
              link: `/project-home/${projectId}`,
            },
          ]}
        />
        <Subheader
          isAdmin={isAdmin}
          page={languageData.ProjectHomeAccessTitle}
          title={projectData.name}
          languageData-testid="subheader"
          handleSessionModal={handleSessionModal}
          handleClientOrg={handleClientOrg}
          handleUserManagement={handleUserManagement}
          handleFileManager={handleFileManager}
          ProjectHomeAccessTitle={t("ProjectHomeAccessTitle")}
          ClientOrgTitleEN={t("ClientOrgTitle")}
          disableUser={!isUserAdmin}
          UserManagementTitleEN={t("UserManagementTitle")}
          NewSessionTitleEN={t("NewSessionTitle")}
          FileManagerButtonTitle={t("FileManager")}
          onChangeSearchInput={handleSearch}
          disableButton={isLoading}
        />
        {state.modalType === languageData.AddSessionPopup && (
          <AddUser
            isOpen={state.isModalOpen}
            onClose={handleCloseModal}
            projectId={projectId}
            NewSessionTitleEN={t("NewSessionTitle")}
            SessionNameContentEN={t("SessionNameContent")}
            SessionFrameworkEN={t("SessionFramework")}
            SelectSessionFrameworkEN={t("SelectSessionFramework")}
            ESRSEN={t("ESRS")}
            ISSBEN={t("ISSB")}
            GRIEN={t("GRI")}
            CancelButtonEN={t("Cancel")}
            DoneButtonEN={t("DoneButton")}
            page={languageData.AddSessionPopup}
            SessionNameEN={t("SessionName")}
            AddSessionPopupEN={t("AddSessionPopup")}
            callApi={createSession}
            sessionFrameWorkArray={sessionFrameWorkDropDown}
          />
        )}
        {state.modalType === languageData.EditSessionPopup && (
          <AddUser
            isOpen={state.isModalOpen}
            onClose={handleCloseModal}
            NewSessionTitleEN={t("EditSessionTitle")}
            SessionNameContentEN={t("SessionNameContent")}
            SessionFrameworkEN={t("SessionFramework")}
            SelectSessionFrameworkEN={t("SelectSessionFramework")}
            sessionId={state.sessionId}
            SelectedsessionName={state.sessionName}
            SelectedsessionFramework={state.sessionFramework}
            AddModulePopupEN={t("AddModulePopup")}
            NewModuleTitleEN={t("NewModuleTitle")}
            SessionNameEN={t("SessionName")}
            ModuleEN={t("Module")}
            SelectModuleEN={t("SelectModule")}
            ESRSEN={t("ESRS")}
            CancelButtonEN={t("Cancel")}
            DoneButtonEN={t("DoneButton")}
            page={languageData.EditSessionPopup}
            EditSessionPopupEN={t("EditSessionPopup")}
            callApi={updateSession}
            handleDeleteSession={() =>
              handleDeleteSessionModal(state.sessionId, state.sessionName)
            }
            DeleteButton={t("DeleteLabel")}
            sessionFrameWorkArray={sessionFrameWorkDropDown}
          />
        )}
        {state.modalType === languageData.RetireSessionPopup && (
          <AddUser
            isOpen={state.isModalOpen}
            onClose={handleCloseModal}
            page={languageData.RetireSessionPopup}
            RetireSessionPopup={t("RetireSessionPopup")}
            ConfirmationTitle={t("DeleteLabel")}
            ConfirmationEN={t("DeleteLabel")}
            ConfirmationDeleteMsgEN={t("ConfirmationRetireSession")}
            CancelButtonEN={t("Cancel")}
            sessionId={state.sessionId}
            callApi={handleRetireSession}
          />
        )}
        {isError}
        {isAdmin && (
          <ProjectDetails
            languageData-testid="project-details"
            ProjectDetailTitle={t("ProjectDetailTitle")}
            EditEN={t("Edit")}
            projectName={t("ProjectName")}
            clientName={t("ClientName")}
            country={t("Country")}
            engagementcode={t("Engagementcode")}
            projectSizeUSD={t("ProjectSizeUSD")}
            clientSector={t("ClientSector")}
            projectAdministrator={"ProjectAdministrator"}
            data={projectData}
            handleEditNavigate={handleEditNavigate}
          />
        )}
        <Accordion
          data={sessionsData || []}
          isAdmin={isAdmin}
          languageData-testid="accordion"
          handleModuleModal={handleModuleModal}
          handleEditSessionModal={handleEditSessionModal}
          handleDeleteSessionModal={handleDeleteSessionModal}
          handleEditModuleModal={handleEditModuleModal}
          ModuleTitleEN={t("ModuleTitle")}
          projectName={projectName}
          sessionData={sessionsData}
          projectId={projectId}
        />
        {state.modalType === languageData.AddModulePopup && (
          <AddUser
            isOpen={state.isModuleModalOpen}
            onClose={handleModuleCloseModal}
            projectId={projectId}
            sessionId={state.sessionId}
            SelectedsessionName={state.sessionName}
            AddModulePopupEN={t("AddModulePopup")}
            NewModuleTitleEN={t("NewModuleTitle")}
            SessionNameEN={t("SessionName")}
            ModuleEN={t("Module")}
            SelectModuleEN={t("SelectModule")}
            DMAEN={t("DMA")}
            HLEN={t("HL")}
            DDEN={t("DD")}
            CancelButtonEN={t("Cancel")}
            DoneButtonEN={t("DoneButton")}
            page={languageData.AddModulePopup}
            callApi={createModule}
            clientReadyLabel={t("clientReadyLabel")}
            nameLabel={t("Name")}
            enterNamePlaceholder={t("enterNamePlaceholder")}
            clientReadyPlaceholder={t("channelPlaceholder")}
            yesLabel={t("yesLabel")}
            noLabel={t("noLabel")}
            moduleTypes={moduleTypes}
          />
        )}

        {state.modalType === languageData.EditModulePopup && (
          <AddUser
            isOpen={state.isModuleModalOpen}
            onClose={handleModuleCloseModal}
            sessionId={state.sessionId}
            SelectedsessionName={state.sessionName}
            AddModulePopupEN={t("AddModulePopup")}
            EditModulePopupEN={t("EditModulePopup")}
            NewModuleTitleEN={t("EditModuleTitle")}
            SessionNameEN={t("SessionName")}
            ModuleEN={t("Module")}
            SelectModuleEN={t("SelectModule")}
            DMAEN={t("DMA")}
            HLEN={t("HL")}
            DDEN={t("DD")}
            CancelButtonEN={t("Cancel")}
            DoneButtonEN={t("UpdateButton")}
            page={languageData.EditModulePopup}
            callApi={updateModuleId}
            clientReadyLabel={t("clientReadyLabel")}
            nameLabel={t("Name")}
            enterNamePlaceholder={t("enterNamePlaceholder")}
            clientReadyPlaceholder={t("channelPlaceholder")}
            yesLabel={t("yesLabel")}
            noLabel={t("noLabel")}
            moduleTypes={moduleTypes}
            moduleNameUpdated={moduleDataById?.name}
            isClientReadyUpdated={
              moduleDataById?.isClientReady === true ? "Yes" : "No"
            }
            moduleTypeId={moduleDataById?.moduleTypeId}
            moduleIdToUpdateApi={moduleDataById?.id}
            DeleteButton={t("DeleteLabel")}
            handleDeleteSession={handleModuleCloseModal}
          />
        )}
      </div>
      {isLoading && (
        <MotifProgressLoader className="loader" show variant="default" />
      )}
    </div>
  );
};

export default ProjectHome;
